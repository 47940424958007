import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'spaceToDash'
})
export class SpaceToDashPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if(value) {
      return value.trim().split(" ").join("-").split("/").join("-");
    }
  }

}
