import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class FormsDbService {

  messagesRef: AngularFireList<any> = this.db.list('forms/messages', ref => ref.orderByChild("opened"));
  cvsRef: AngularFireList<any> = this.db.list('forms/cvs', ref => ref.orderByChild("opened"));
  messages: Observable<any[]>;
  cvs: Observable<any[]>;

  unreadedMessages;

  constructor(private db: AngularFireDatabase, private router: Router) {}

  getAllMessages() {
    this.messages = this.messagesRef.snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    )
    return this.messages;
  }

  getAllCvs() {
    this.cvs = this.cvsRef.snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    )
    return this.cvs;
  }

  changeOpenedVal(db, key){
    let itemRef;
    itemRef = this.db.object(`${db}${key}`);

    itemRef.update({
      opened: true
    })
  }

  // getSingleJob(key) {
  //   return this.db.object(`jobs/${key}`).snapshotChanges().pipe(
  //     map(changes => ( { key: changes.payload.key, ...changes.payload.val() } ))
  //   )
  // }


  // updateSingleJob(job) {
  //   const jobAlias = job.title.split(" ").join("-").toLowerCase();
  //   this.db.object(`jobs/${job.key}`).update({
  //     alias: jobAlias,
  //     title: job.title,
  //     teaser: job.teaser,
  //     image: job.image,
  //     body: job.body,
  //     show: job.show
  //   });
  //   this.router.navigate(['wasadmin/jobs']);
  // }



}
