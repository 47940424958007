import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { JobsDbService } from '../jobs-db.service';
import { MatTableDataSource } from '@angular/material/table';
import { map } from 'rxjs/operators';


@Component({
  selector: 'app-jobs-admin',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss']
})
export class JobsComponent implements OnInit {
  keysVisible = false;
  jobsList;
  displayedColumns = ['title', 'id', 'hidden', 'actions'];
  dataSource = new MatTableDataSource<any>();

  constructor(private jobsDbService: JobsDbService, private router: Router) { }

  ngOnInit() {
    // this.jobsDbService.getAllJobs().subscribe(data => this.dataSource.data = data);
    // this.jobsDbService.getAllJobs().subscribe(data => this.dataSource.data = data.filter(item => item.removed === false)); OLD SOLUTION
    this.jobsDbService.getAllJobs()
    .pipe(map(data => data.filter(job => job.removed === false)))
    .subscribe(data => this.dataSource.data = data);

    // this.jobs = this.jobsDbService.getAllJobs();
  }

  jobEdit(key) {
    this.router.navigate([`/wasadmin/job-edit/${key}`]);
  }

  jobDelete(job) {
    this.jobsDbService.deleteJob(job);
  }

  onCheckbox(event, key){
    this.jobsDbService.changeShowVal(key, event.checked);
  }
 
}
