import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-job-preview',
  templateUrl: './job-preview.component.html',
  styleUrls: ['./job-preview.component.scss']
})
export class JobPreviewComponent implements OnInit {
@Input('image-prev') image;
@Input('title-prev') title;
@Input('body-prev') body = "example body";
  constructor() { }

  ngOnInit() {
    
  }

}
