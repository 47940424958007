import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { MailService } from '../../mail.service';
import { tap, finalize } from 'rxjs/operators';
import { NgForm, FormGroup, FormControl, Validators, FormControlName } from '@angular/forms'
import { FormsService } from '../../forms.service';
import moment from 'moment';
import { AngularFireStorage } from '@angular/fire/storage';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-send-cv',
  templateUrl: './send-cv.component.html',
  styleUrls: ['./send-cv.component.scss']
})
export class SendCvComponent implements OnInit {

  name: string;
  email: string;

  form: FormGroup;
  cvPath;
  correctExtension = true;
  cvConfirmed = false;

  displayErrors = false;
  hideForm = false;
  @Output() back = new EventEmitter()
  @Input()  alias: string;

  @ViewChild('submit') submitBtn: ElementRef;

  constructor(private mailService: MailService, private formsService: FormsService, private storage: AngularFireStorage) {}

  ngOnInit() {
    this.form = new FormGroup({
      name: new FormControl('', {validators: [Validators.required, Validators.minLength(3)]}),
      email: new FormControl('', {validators: [Validators.required, Validators.minLength(3), Validators.email]}),
      cvFile: new FormControl('', {validators: [Validators.required]}),
      type: new FormControl('unknown')
    });
  }

  onFormSubmit() {
    this.form.value.type = this.alias;
    this.formsService.addCv(this.form.value);
    this.cvConfirmed = true;
  }

  onSent() {
    this.hideForm = true;
    setTimeout(()=>{ 
      this.hide();
  }, 3000);
  }

  hide(){
      this.back.emit(true);
  }


  uploadFile(event) {
    const file = event.target.files[0];
    const fileName = this.generateRandomName();
    const today = moment().format('YYYY-MM-DD');
    const filePath = 'cvs/' + today + '-' + fileName;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    this.correctExtension = this.validateFile(file.name);
    
    // get notified when the download URL is available
    task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().toPromise().then(res => {
            if(res !== null){
              this.cvPath = res;
            }
              this.form.patchValue({
                cvFile: res
              })
            })
        })
     )
    .subscribe()
  }

  generateRandomName() {
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < 10; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'png' ||
        ext.toLowerCase() == 'jpg' ||
        ext.toLowerCase() == 'jpeg' ||
        ext.toLowerCase() == 'doc' ||
        ext.toLowerCase() == 'pdf') {
        return true;
    }
    else {
        return false;
    }
}
}
