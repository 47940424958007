import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

// import Jobs from '../assets/jobs.json';
import { AngularFireDatabase, AngularFireList, AngularFireAction } from '@angular/fire/database';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class JobsService {

  // private jobs: {} = Jobs;
  public allJobs;
  public visibleJobs;
  public allJobs$ = new BehaviorSubject<Array<any>>([]);
  public visibleJobs$ = new BehaviorSubject<Array<any>>([]);
  public single;

  public job$: BehaviorSubject<string|null>;
  public jobs$: Observable<AngularFireAction<firebase.database.DataSnapshot>[]>;
  

  constructor(private db: AngularFireDatabase) {
    this.db.list('jobs').valueChanges().subscribe(res => {
      this.allJobs = res;
      this.visibleJobs = res;
      this.allJobs$.next(this.allJobs);
      this.visibleJobs$.next(this.allJobs.filter(item => item.show === true))
    });

    this.job$ = new BehaviorSubject(null);
    this.jobs$ = this.job$.pipe(
      switchMap(item => 
        db.list('jobs', ref =>
        item ? ref.orderByChild('alias').equalTo(item) : ref
        ).snapshotChanges()
    )
    );
  }

  getAllJobs() {
    return this.allJobs$;
  }

  getVisibleJobs() {
    return this.visibleJobs$;
  }
}
