import { MenuService } from './../menu/menu.service';
import { Job } from './../job.model';
import { JobsService } from './../jobs.service';
import { Component, OnInit, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-job-single',
  templateUrl: './job-single.component.html',
  styleUrls: ['./job-single.component.scss']
})
export class JobSingleComponent implements OnInit {

  jobRoute: string;
  singleJob;

  back;

  test$ = new Subject<string>();
  modalVisible: boolean = false;

  constructor(private route: ActivatedRoute, private jobsService: JobsService, private menuService: MenuService, private db: AngularFireDatabase) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.jobRoute = params.id
      // alljobs
      // .filter()
      // single = 
      // this.singleJob = this.jobsService.getSingleJob(this.jobRoute);
    });
  }

  ngDoCheck() {
    this.jobsService.getAllJobs()
    .pipe(map(data => data.filter(item => item.alias === this.jobRoute)))
    .subscribe(res => this.singleJob = res[0]);
  }

  showMenu() {
    this.menuService.toggleMenu();
  }

  onSendCvButton(){
    this.modalVisible = !this.modalVisible;
    if(this.modalVisible === true){
      window.scroll(0,0);
    }
  }

}
