import { Router } from '@angular/router';
import { AuthService } from './../auth/auth.service';
import { Component, OnInit, OnChanges, DoCheck } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, DoCheck {
  actualPageHeader ="";
  isLoggedIn: boolean;
  items: Observable<any[]>;

  constructor(db: AngularFirestore, private authService: AuthService, private router: Router) {
    this.items = db.collection('items').valueChanges();
  }

  ngOnInit() {
    this.authService.authChange.subscribe(user => this.isLoggedIn = user);
    if(!this.isLoggedIn) {
      this.router.navigate(['wasadmin/login']);
    }
  }

  ngDoCheck(){
    this.actualPageHeader = getHeaderName(this.router.url);
  }

}

function getHeaderName(url){
  let actualPage: string;
  actualPage = url;
  actualPage = actualPage.substring(10);
  actualPage = actualPage.replace(/jobs-|job-/, ' ');
  if(url.includes('edit')){
    actualPage = actualPage.substring(0, actualPage.indexOf('/'));
  }
  if(url === "/wasadmin/login"){
    actualPage = "";
  }
  return actualPage;
}