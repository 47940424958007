import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

// import messages from '../assets/messages.json';
import { AngularFireDatabase, AngularFireList, AngularFireAction } from '@angular/fire/database';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FormsService {

  // private messages: {} = messages;
  public allMessages;
  public allMessages$ = new BehaviorSubject<Array<any>>([]);
  public single;
  messagesRef: AngularFireList<any> = this.db.list('forms/messages');
  cvsRef: AngularFireList<any> = this.db.list('forms/cvs');

  public message$: BehaviorSubject<string|null>;
  public messages$: Observable<AngularFireAction<firebase.database.DataSnapshot>[]>;
  

  constructor(private db: AngularFireDatabase) {
    this.db.list('forms/messages').valueChanges().subscribe(res => {
      this.allMessages = res;
      this.allMessages$.next(this.allMessages);
    });

    this.message$ = new BehaviorSubject(null);
    this.messages$ = this.message$.pipe(
      switchMap(item => 
        db.list('forms/messages', ref =>
        item ? ref.orderByChild('alias').equalTo(item) : ref
        ).snapshotChanges()
    )
    );
  }

  getAllMessages() {
    return this.allMessages$;
  }

  addMessage(newMessage){
    this.messagesRef.push({
      opened: false,
      date: this.getDate(),
      ...newMessage
    });
  }

  addCv(newCv){
    let date = new Date();
    this.cvsRef.push({
      opened: false,
      date: this.getDate(),
      ...newCv
    });
  }

  getDate(){
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];
    let dateObj = new Date();
    let month = monthNames[dateObj.getMonth()];
    let day = String(dateObj.getDate()).padStart(2, '0');
    let year = dateObj.getFullYear();
    let output = month  + ' ' + day  + ', ' + year;

    return output;
  }
}
