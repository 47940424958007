import { JobsService } from './../jobs.service';
import { MenuService } from './menu.service';
import { Component, OnInit } from '@angular/core';
import { Job } from '../job.model';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  allJobs: Array<any>;

  constructor(private menuService: MenuService, private jobsService: JobsService) { }

  ngOnInit() {

    this.jobsService.getAllJobs()
    .pipe(map(data => data.filter(job => job.show !== false)))
    .subscribe(data => this.allJobs = data);

  }

  closeMenu() {
    this.menuService.toggleMenu();
  }

}
