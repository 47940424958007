import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-we-do',
  templateUrl: './we-do.component.html',
  styleUrls: ['./we-do.component.scss']
})
export class WeDoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
