import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../../environments/environment';

import { MaterialModule } from './../shared/material.module';

import { AdminRoutingModule } from './admin-routing.module';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './auth/login/login.component';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { NavbarComponent } from './navbar/navbar.component';
import { JobsComponent } from './jobs/jobs.component';
import { JobsAddComponent } from './jobs-add/jobs-add.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SpaceToDashPipe } from './shared/space-to-dash.pipe';
import { JobsEditComponent } from './jobs-edit/jobs-edit.component';
import { JobsTrashComponent } from './jobs-trash/jobs-trash.component';
import { JobPreviewComponent } from './shared/job-preview/job-preview.component';
import { MessagesComponent } from './messages/messages.component';
import { CvsComponent } from './cvs/cvs.component';


@NgModule({
  declarations: [HomeComponent, LoginComponent, NavbarComponent, JobsComponent, JobsAddComponent, SpaceToDashPipe, JobsEditComponent, JobsTrashComponent, JobPreviewComponent, MessagesComponent, CvsComponent],
  imports: [
    CommonModule,
    AdminRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    CKEditorModule
  ]
})
export class AdminModule { }
