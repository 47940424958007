import { JobsDbService } from './../jobs-db.service';
import { Component, OnInit } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NgForm } from '@angular/forms';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import moment from 'moment';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-jobs-add',
  templateUrl: './jobs-add.component.html',
  styleUrls: ['./jobs-add.component.scss']
})
export class JobsAddComponent implements OnInit {
  modalVisible: boolean = false;
  imgPath;
  form: FormGroup;
  // TOFIX UploadFiles - should be moved to service
  uploadPercent: Observable<number>;
  downloadURL: Observable<string | null>;

  public Editor = ClassicEditor;
  public model = {
    editorData: `<h2>Opis Stanowiska</h2>
    <ul>
     <li>Lorem ipsum dolor sit amet consectetur adipiscing elit</li>
    </ul>
    <h2>Wymagania</h2>
    <ul>
     <li>Duis id orci tempus, finibus leo eget volutpat dolor</li>
    </ul>
    <h2>Oferujemy</h2>
    <ul>
     <li>Etiam malesuada diam sed eros elementum</li>
    </ul>`
  };

  constructor(private jobsDbService: JobsDbService, private storage: AngularFireStorage) { }

  ngOnInit() {
    this.form = new FormGroup({
      title: new FormControl('', {validators: [Validators.required, Validators.minLength(5)]}),
      teaser: new FormControl('', {validators: [Validators.required, Validators.minLength(10)]}),
      image: new FormControl('assets/jobs/01.jpg'),
      body: new FormControl(this.model.editorData, {validators: [Validators.required]}),
      show: new FormControl(true)
  });
  this.imgPath = this.form.value.image;
  }

  onSubmit() {
    this.jobsDbService.addNewJob(this.form.value);
  }


  uploadFile(event) {
    const file = event.target.files[0];
    const fileName = this.generateRandomName();
    const today = moment().format('YYYY-MM-DD');
    const filePath = 'images/jobs/' + today + '-' + fileName;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);
    
    // observe percentage changes
    this.uploadPercent = task.percentageChanges();
    // get notified when the download URL is available
    task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().toPromise().then(res => {
            if(res !== null){
              this.imgPath = res;
            }
              this.form.patchValue({
                image: res
              })
            })
        })
     )
    .subscribe()
  }

  generateRandomName() {
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < 10; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  onPreview(){
    this.modalVisible = !this.modalVisible;
    if(this.modalVisible === true){
      window.scroll(0,0);
    }
  }


}
