import { AdminModule } from './admin/admin.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ContactFormComponent } from './contact-form/contact-form.component';
import { HeaderComponent } from './header/header.component';
import { AboutComponent } from './about/about.component';
import { WeDoComponent } from './we-do/we-do.component';
import { JobsComponent } from './jobs/jobs.component';
import { JobsService } from './jobs.service';
import { JobComponent } from './job/job.component';
import { HomepageComponent } from './homepage/homepage.component';
import { JobSingleComponent } from './job-single/job-single.component';
import { MenuComponent } from './menu/menu.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { FormsService } from './forms.service';
import { SendCvComponent } from './job-single/send-cv/send-cv.component';

@NgModule({
  declarations: [
    AppComponent,
    ContactFormComponent,
    HeaderComponent,
    AboutComponent,
    WeDoComponent,
    JobsComponent,
    JobComponent,
    HomepageComponent,
    JobSingleComponent,
    MenuComponent,
    PageNotFoundComponent,
    SendCvComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    AdminModule,
    BrowserAnimationsModule,
  ],
  providers: [
    JobsService,
    FormsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
