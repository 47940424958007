import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { JobsDbService } from '../jobs-db.service';
import { MatTableDataSource } from '@angular/material/table';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-jobs-trash',
  templateUrl: './jobs-trash.component.html',
  styleUrls: ['./jobs-trash.component.scss']
})
export class JobsTrashComponent implements OnInit {
  jobsList;
  displayedColumns = ['title', 'id', 'actions'];
  dataSource = new MatTableDataSource<any>();
  dataSourceRemoved = new MatTableDataSource<any>();

  constructor(private jobsDbService: JobsDbService, private router: Router) { }

  ngOnInit() {
    this.jobsDbService.getAllJobs()
    .pipe(map(data => data.filter(job => job.removed === true)))
    .subscribe(data => this.dataSource.data = data);
  };

  jobRestore(job) {
    this.jobsDbService.restoreJob(job);
  }

}
