import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  menu: boolean = false;
  menuStatus = new Subject<boolean>();

  constructor() { }

  toggleMenu() {
    this.menu = !this.menu;
    this.menuStatus.next(this.menu);
  }
}
