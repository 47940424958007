import { JobsEditComponent } from './jobs-edit/jobs-edit.component';
import { JobsComponent } from './jobs/jobs.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './auth/login/login.component';
import { AuthGuard } from './auth/auth.guard';
import { JobsAddComponent } from './jobs-add/jobs-add.component';
import { JobsTrashComponent } from './jobs-trash/jobs-trash.component';
import { MessagesComponent } from './messages/messages.component';
import { CvsComponent } from './cvs/cvs.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      {path: 'login', component: LoginComponent},
      {path: 'messages', component: MessagesComponent, canActivate: [AuthGuard]},
      {path: 'cvs', component: CvsComponent, canActivate: [AuthGuard]},
      {path: 'jobs', component: JobsComponent, canActivate: [AuthGuard]},
      {path: 'jobs-add', component: JobsAddComponent, canActivate: [AuthGuard]},
      {path: 'jobs-trash', component: JobsTrashComponent, canActivate: [AuthGuard]},
      {path: 'job-edit/:id', component: JobsEditComponent, canActivate: [AuthGuard]},
    ]},
    {
      path: '**',
      redirectTo: 'wasadmin',
      pathMatch: 'full'
    }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AdminRoutingModule { }
