import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormsDbService } from '../forms-db.service';
import { MatTableDataSource } from '@angular/material/table';
import { map } from 'rxjs/operators';
import { DataSource } from '@angular/cdk/table';

@Component({
  selector: 'app-cvs',
  templateUrl: './cvs.component.html',
  styleUrls: ['./cvs.component.scss']
})
export class CvsComponent implements OnInit {
  displayedColumns = ['date', 'name', 'email', 'cv', 'type'];
  dataSource = new MatTableDataSource<any>();
  opened = false;
  db = 'forms/cvs/';

  constructor(private formsDbService: FormsDbService, private router: Router) { }

  ngOnInit() {
    this.formsDbService.getAllCvs()
    .subscribe(data => this.dataSource.data = data);

    // this.formsDbService.getAllMessages()
    // .pipe(map(data => data.filter(item => item.opened === true)))
    // .subscribe(data => this.opened = data);
  }

  changeOpened(key){
    this.formsDbService.changeOpenedVal(this.db, key);
  }
 
}