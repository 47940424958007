import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MailService } from '../mail.service';
import { tap } from 'rxjs/operators';
import { NgForm, FormGroup, FormControl, Validators } from '@angular/forms'
import { FormsService } from '../forms.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit{

  name: string;
  mail: string;
  message: string;
  invalid = false
  form: FormGroup;

  displayErrors = false;
  disabled = true;
  disabledForm = false;
  sent = false;

  @ViewChild('submit') submitBtn: ElementRef;

  constructor(private mailService: MailService, private formsService: FormsService) {}

  ngOnInit() {
    this.form = new FormGroup({
      name: new FormControl('', {validators: [Validators.required, Validators.minLength(3)]}),
      email: new FormControl('', {validators: [Validators.required, Validators.minLength(3), Validators.email]}),
      message: new FormControl('', {validators: [Validators.required, Validators.minLength(10)]}),
    });
  }

  onFormSubmit(form: NgForm) {
    // if (form.form.status === 'VALID') {
    //   this.mailService.postVars = { name: this.name, mail: this.mail, message: this.message };
    //   this.mailService.sendMail();
    //   this.mailService.status$
    //     .pipe(
    //       tap(
    //         (status) => {
    //           if (status === 'sent') {
    //             this.onSent();
    //           }
    //           if (status === 'error') {
    //             this.onError();
    //           }
    //         }
    //       )
    //     )
    //     .subscribe();
    // }
    // else {
    //   this.displayErrors = true;
    // }
    this.formsService.getAllMessages();
    this.formsService.addMessage(this.form.value);

  }

  onSent() {
    this.disabled = true;
    this.disabledForm = true;
    this.sent = true;
    this.submitBtn.nativeElement.textContent = "Dziękujemy za wysłanie wiadomości";
  }

  onError() {
    this.submitBtn.nativeElement.textContent = "Błąd";
  }

}
