import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MailService {

  postVars: { name: string, mail: string, message: string };
  private endpoint = '/mail.php';

  private statusSubject = new BehaviorSubject<string>('ready');
  public status$ = this.statusSubject.asObservable();

  constructor(private http: HttpClient) { }

  sendMail() {
    this.http.post(this.endpoint, this.postVars)
    .subscribe(
      () => this.statusSubject.next('sent'),
      () => this.statusSubject.next('error')
    )
  }

}
