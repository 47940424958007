import { Component, OnInit } from '@angular/core';
import { JobsService } from '../jobs.service';
import { Job } from '../job.model';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss']
})
export class JobsComponent implements OnInit {
  // jobs = [Job];
  allJobs: Array<any>;
  visibleJobs : Array<any>;

  constructor(private jobsService: JobsService) {
  }

  ngOnInit() {
    // this.jobs = this.jobsService.getJobs()["jobs"];
    this.jobsService.getAllJobs().subscribe(jobs => this.allJobs = jobs);
    this.jobsService.getVisibleJobs().subscribe(jobs => this.visibleJobs = jobs);
    // this.jobsService.getAllJobs().subscribe
  }

}
