import { AuthService } from './../auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FormsDbService } from '../forms-db.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  isLoggedIn: boolean;
  unreadedMsg: number;
  unreadedCvs: number
  constructor(private formsDbService: FormsDbService, private authService: AuthService) { }

  ngOnInit() {
    this.authService.authChange.subscribe(user => this.isLoggedIn = user);
    this.formsDbService.getAllMessages().pipe(map(data => data.filter(message => message.opened === false)))
    .subscribe(data => this.unreadedMsg = data.length);
    this.formsDbService.getAllCvs().pipe(map(data => data.filter(cv => cv.opened === false)))
    .subscribe(data => this.unreadedCvs = data.length);
  }

  logout() {
    this.authService.logout();
  }

}
