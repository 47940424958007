import { Job } from '../job.model';
import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class JobsDbService {

  jobsRef: AngularFireList<any> = this.db.list('jobs');
  jobs: Observable<any[]>;
  public removedJobs$ = new BehaviorSubject<Array<any>>([]);
  removedJobs;

  constructor(private db: AngularFireDatabase, private router: Router) {}

  getAllJobs() {
    this.jobs = this.jobsRef.snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    )
    return this.jobs;
  }

  getSingleJob(key) {
    return this.db.object(`jobs/${key}`).snapshotChanges().pipe(
      map(changes => ( { key: changes.payload.key, ...changes.payload.val() } ))
    )
  }

  addNewJob(newJob) {
    let jobAlias = newJob.title.split(" ").join("-").split("/").join("-").toLowerCase();
    const id = Math.floor(Math.random() * (9999 - 1000)) + 1000
    jobAlias = jobAlias + "-" + id;
    this.jobsRef.push({
      alias: jobAlias,
      removed: false,
      id: id,
      ...newJob
    });
    this.router.navigate(['wasadmin/jobs']);
  }

  updateSingleJob(job) {
    const jobAlias = job.title.split(" ").join("-").split("/").join("-").toLowerCase();
    this.db.object(`jobs/${job.key}`).update({
      alias: jobAlias,
      title: job.title,
      teaser: job.teaser,
      image: job.image,
      body: job.body,
      show: job.show
    });
    this.router.navigate(['wasadmin/jobs']);
  }

  changeShowVal(job, val) {
    this.db.object(`jobs/${job.key}`).update({
      show: val
    });
  }

  deleteJob(job) {
    this.db.object(`jobs/${job.key}`).update({
      removed: true,
      show: false
    });
  }

  restoreJob(job){
    this.db.object(`jobs/${job.key}`).update({
      removed: false,
      show: false
    })
  }

  getJobs() {
    this.jobs = this.jobsRef.valueChanges();
    return this.jobs;
  }




}
