import { AuthData } from './auth-data.model';
import { Injectable } from '@angular/core';
import { User } from './user.model';
import { AngularFireAuth } from '@angular/fire/auth';
import { Subject, BehaviorSubject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  authChange = new BehaviorSubject<boolean>(false);
  private user: User;

  constructor(private auth: AngularFireAuth, private router: Router, private acRoute: ActivatedRoute) {
    // this.checkToken();
  }

  // checkToken() {
  //   if(localStorage.getItem('wa-user')) {
  //     this.user = JSON.parse(localStorage.getItem('wa-user'));
  //     this.authChange.next(true);
  //   }
  // }

  login(authData: AuthData) {
    const msg = new Subject<string>();
    this.auth.auth.signInWithEmailAndPassword(authData.email, authData.password)
      .then(result => {
        this.user = { email: result.user.email, uid: result.user.uid };
        this.authChange.next(true);
        this.router.navigate(['wasadmin/jobs'], { relativeTo: this.acRoute });
        // localStorage.setItem('wa-user', JSON.stringify(this.user));
      })
      .catch(err => {
        msg.next(err.message);
      })
    return msg;
  }

  logout() {
    this.user = null;
    this.authChange.next(false);
    this.router.navigate(['wasadmin/login'], { relativeTo: this.acRoute });
    // localStorage.removeItem('wa-user');
  }

  getUser() {
    // return this.user
    return { ...this.user }
  }

  isAuth() {
    return this.user != null;
  }
}
