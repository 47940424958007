import { AuthService } from './../auth.service';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/platform-browser';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  msg: string;

  constructor(@Inject(DOCUMENT) private document: Document, private authService: AuthService) { }

  ngOnInit() {
    this.document.body.classList.add('login');
  }

  ngOnDestroy() {
    this.document.body.classList.remove('login');
  }

  onSubmit(form: NgForm) {
    this.authService.login({
      email: form.value.email,
      password: form.value.password
    })
    .subscribe(response => this.msg = response);
  }

}
